/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../../../components/Modal/assets/closeIcon.svg';

export type EnrichmentModalProps = {
  isOpen: boolean;
  description: string;
  title: string;
  cancelFunc: Function;
};

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    width: '433px',
    minHeight: '205px',
    top: '35%',
  },
  componentStyle: {
    border: 'solid 2px #0070D8',
  },
  heading: {
    padding: '22px 38px 24px',
    border: 'none',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '22px',
    position: 'relative',
  },
  cancelButton: {
    backgroundColor: '#52B36B!important',
    borderRadius: '4px',
    height: '40px',
    width: '100%',
    '&:hover': {
      boxShadow: '0px 2px 15px rgb(0 0 0 / 15%)!important',
      backgroundColor: '#5CB082!important',
    },
  },
  submitButton: {
    height: '40px',
    width: '182px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5CB082!important',
    },
  },
  content: {
    margin: '0',
    overflow: 'hidden',
    wordWrap: 'break-word',
    position: 'relative',
    width: '98%',
    border: 'none',
    padding: '0 38px',
    height: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#282828',
  },
  action: {
    padding: '24px 38px 38px 38px !important',
    width: '100%',
    display: 'flex',
  },
  button: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#22447D!important',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '12px',
    color: '#7A89A7',
  },
});
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const EnrichmentModal: FC<EnrichmentModalProps> = ({
  isOpen,
  description,
  title,
  cancelFunc,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.dialog,
      }}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.heading}>
        <span style={{ fontWeight: 700 }}>{title}</span>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => cancelFunc()}
        >
          <img src={CloseIcon} alt="close icon" />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>{description}</DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={() => {
            cancelFunc();
            navigate('/lid-page');
          }}
          size="large"
          variant="contained"
          color="secondary"
          className={classes.cancelButton}
        >
          <span style={{ fontSize: '14px', color: '#fff' }}>
            Check Enrichment Status
          </span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnrichmentModal;
