import React, { FC } from 'react';
import s from './style/GhostLink.module.scss';
import { CircleTooltip } from '../CircleTooltip';

type GhostLinkProps = {
  tooltipText: string;
  buttonText: string;
  href: string;
};

const GhostLink: FC<GhostLinkProps> = ({ buttonText, href, tooltipText }) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <a className={s.button} href={href} target="_blank">
    <span className={s.button__text}>{buttonText}</span>

    <CircleTooltip
      description={tooltipText}
      fullWidth
      width="220px"
      customIcon={
        <svg
          style={{ width: '14px', height: '14px' }}
          width="14"
          height="14"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 9C0 4.03124 4.02979 0 9 0C13.9702 0 18 4.03124 18 9C18 13.9717 13.9702 18 9 18C4.02979 18 0 13.9717 0 9ZM1.74199 8.99984C1.74199 13.0124 4.98881 16.2579 9.00006 16.2579C13.0126 16.2579 16.2581 13.011 16.2581 8.99984C16.2581 4.9887 13.0098 1.74177 9.00006 1.74177C4.98896 1.74177 1.74199 4.98994 1.74199 8.99984ZM9.00017 3.99177C9.84196 3.99177 10.5244 4.67418 10.5244 5.51597C10.5244 6.35776 9.84196 7.04016 9.00017 7.04016C8.15838 7.04016 7.47598 6.35776 7.47598 5.51597C7.47598 4.67418 8.15838 3.99177 9.00017 3.99177ZM10.597 13.6451C10.8375 13.6451 11.0325 13.4501 11.0325 13.2096V12.3387C11.0325 12.0982 10.8375 11.9032 10.597 11.9032H10.1615V8.27416C10.1615 8.03366 9.96653 7.83867 9.72603 7.83867H7.40345C7.16296 7.83867 6.96797 8.03366 6.96797 8.27416V9.14512C6.96797 9.38562 7.16296 9.58061 7.40345 9.58061H7.83894V11.9032H7.40345C7.16296 11.9032 6.96797 12.0982 6.96797 12.3387V13.2096C6.96797 13.4501 7.16296 13.6451 7.40345 13.6451H10.597Z"
            fill="#7A89A7"
          />
        </svg>
      }
      iconStyle={{ display: 'flex ' }}
    />
  </a>
);

export default GhostLink;
