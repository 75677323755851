/* eslint-disable */
import React, { ReactNode, FC } from 'react';
import { BreadcrumbsArrayConfiguration } from '../Breadcrumbs/Breadcrumbs';
import BreadcrumbsComponent from '../BreadcrumbsComponent';
import s from './PageTittle.module.scss';
import ButtonLink from '../ButtonLink';

type PageWrapperProps = {
  children?: ReactNode | null;
  pageCrumbs: Array<BreadcrumbsArrayConfiguration>;
  pageTitle: string;
  contextElements?: ReactNode | null;
  isTestStatusPassed?: boolean;
  wrapperStyle?: React.CSSProperties;
  tooltipText?: string;
  showTooltip?: boolean;
  customText?: string;
  headerActions?: ReactNode;
};

const PageTittle: FC<PageWrapperProps> = ({
  children,
  pageTitle,
  pageCrumbs,
  contextElements,
  isTestStatusPassed,
  wrapperStyle,
  tooltipText,
  showTooltip,
  customText,
  headerActions,
}) => (
  <div className={`${s.wrapper}`}>
    <div className={s.wrapper__header}>
      <div className={s.wrapper__title}>
        <BreadcrumbsComponent
          isLID={pageTitle === 'LID Dashboard'}
          isTestPassed={isTestStatusPassed}
          titleName={pageTitle}
          crumbs={pageCrumbs}
          wrapperStyle={wrapperStyle}
          tooltipText={tooltipText}
          showTooltip={showTooltip}
          customText={customText}
        />
      </div>
      {headerActions && (
        <div className={s.wrapper__actions}>{headerActions}</div>
      )}
    </div>
    {contextElements !== undefined && (
      <div className={s.wrapper__content}>{contextElements}</div>
    )}
    <div className={s.wrapper__main}>{children}</div>
  </div>
);

export default PageTittle;
