/* eslint-disable */
import React, { FC } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, makeStyles } from '@material-ui/core/styles';
import tooltip from './tooltip.svg';
import errorTooltip from './errorTooltip.svg';

interface CircleTooltipType {
  description: string | React.ReactElement;
  position?: 'top' | 'left' | 'right' | 'bottom';
  fullWidth?: boolean;
  isError?: boolean;
  width?: string;
  iconStyle?: React.CSSProperties;
  marginBottom?: string;
  customIcon?: React.ReactNode;
}

const CircleTooltip: FC<CircleTooltipType> = ({
  description,
  isError = false,
  position = 'top',
  fullWidth = false,
  width = '350px',
  marginBottom = '8px',
  iconStyle,
  customIcon,
}) => {
  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: '#282828',
      zIndex: 100000,
    },
    tooltip: {
      fontSize: '12px',
      maxWidth: `${fullWidth ? width : '320px'}`,
      padding: '16px',
      marginBottom: marginBottom,
      zIndex: 100000,
      backgroundColor: '#282828',
      color: 'white',
    },
  }));
  const classes = useStylesBootstrap();
  return (
    <Tooltip
      style={{ zIndex: 100000 }}
      classes={classes}
      placement={position}
      title={description}
      interactive
      arrow
    >
      {customIcon ? (
        <span style={iconStyle}>{customIcon}</span>
      ) : (
        <img
          src={isError ? errorTooltip : tooltip}
          alt="ToolTip"
          style={iconStyle}
        />
      )}
    </Tooltip>
  );
};

export { CircleTooltip };
