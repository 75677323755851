import React, { createContext, useContext, useState, useEffect } from 'react';
import { useQueries } from 'react-query';
import { GetEnrichStatus, ResponseType } from '../api';

type EnrichmentContextType = {
  enrichedStatus: boolean;
};

const EnrichmentContext = createContext<EnrichmentContextType | undefined>(
  undefined
);

const processEnrichments = (
  enrichments: { id: number; type: number; state: number }[]
) => {
  const apiStatus = {
    MelissaApi: false,
    ClearinghouseApi_20_24: false,
    ClearinghouseApi_25_34: false,
    ClearinghouseApi_35_40: false,
  };

  enrichments?.forEach(({ type, state }) => {
    const stateIsValid = state === 0 || state === 2;

    if (type === 1) {
      apiStatus.MelissaApi = stateIsValid;
    } else {
      if (type === 2 || type === 6 || type === 10 || type === 14) {
        apiStatus.ClearinghouseApi_20_24 = stateIsValid;
      }
      if (type === 4 || type === 6 || type === 12 || type === 14) {
        apiStatus.ClearinghouseApi_25_34 = stateIsValid;
      }
      if (type === 8 || type === 10 || type === 12 || type === 14) {
        apiStatus.ClearinghouseApi_35_40 = stateIsValid;
      }
    }
  });

  return apiStatus;
};

export const EnrichmentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [enrichmentStates, setEnrichmentStates] = useState({
    MelissaApi: false,
    ClearinghouseApi_20_24: false,
    ClearinghouseApi_25_34: false,
    ClearinghouseApi_35_40: false,
  });

  const results = useQueries([GetEnrichStatus()]);

  const EnrichStatus = results[0]?.data as
    | ResponseType<{ id: number; type: number; state: number }[]>
    | undefined;

  useEffect(() => {
    if (EnrichStatus?.result) {
      setEnrichmentStates(processEnrichments(EnrichStatus.result));
    }
  }, [EnrichStatus?.result]);

  const enrichedStatus =
    enrichmentStates.MelissaApi ||
    enrichmentStates.ClearinghouseApi_20_24 ||
    enrichmentStates.ClearinghouseApi_25_34 ||
    enrichmentStates.ClearinghouseApi_35_40;

  return (
    <EnrichmentContext.Provider value={{ enrichedStatus }}>
      {children}
    </EnrichmentContext.Provider>
  );
};

export const useEnrichment = () => {
  const context = useContext(EnrichmentContext);
  if (context === undefined) {
    throw new Error('useEnrichment must be used within an EnrichmentProvider');
  }
  return context;
};
