/* eslint-disable */
import React, { FC, useState, useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import 'date-fns';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { toast } from '../../components/Toast/ToastManager';
import { useQueryClient } from 'react-query';
import { dataManagmentHttpClient } from '../../../helpers/http';
import documentIcon from './assets/documentIcon.svg';
import CloseIcon from './assets/closeIcon.svg';
import CalendarIcon from './assets/calendarIcon.svg';
import FileUpload from '../FileUpload';
import DownloadWidget from './DownloadWidget';
import s from './Modal.module.scss';
import { CircleTooltip, Loader } from '../index';
import Alert from './Alert';
import EnrichmentModal from '../../containers/DataManagementPage/widgets/AriesPage/EnrichmentModal';
import { useEnrichment } from '../../context/EnrichmentContext';

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;
  className?: string;
}

type ModalProps = {
  buttonText?: string;
  withIcon?: boolean;
  color?: 'primary' | 'secondary';
  colorOverride?: string;
  img?: string;
  width?: string;
  isLid?: boolean;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '14px 20px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1.5),
      color: theme.palette.grey[500],
    },
    button: {
      background: '#52B36B',
      padding: '600px',
    },
  });

const useStyles = makeStyles({
  componentStyle: {
    border: 'solid 2px #0070D8',
  },
  cancelButton: {
    height: '40px',
    '&:hover': {
      filter: 'drop-shadow(0px 2px 15px rgba(122, 137, 167, 0.25))',
      borderRadius: '4px!important',
      background: 'white!important',
      color: '#282828!important',
    },
  },
  submitButton: {
    height: '40px',
    backgroundColor: '#52B36B!important',
    '&:hover': {
      opacity: 0.8,
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
  button: {
    height: '40px',
    '&:hover': {
      boxShadow: ' 0px 2px 10px 4px #C3C5E1',
      backgroundColor: ' rgba(43, 57, 185, 0.8)!important',
    },
  },
});
const processEnrichments = (
  enrichments: { id: number; type: number; state: number }[]
) => {
  const apiStatus = {
    MelissaApi: false,
    ClearinghouseApi_20_24: false,
    ClearinghouseApi_25_34: false,
    ClearinghouseApi_35_40: false,
  };

  enrichments?.forEach(({ type, state }) => {
    const stateIsValid = state === 0 || state === 2;

    if (type === 1) {
      apiStatus.MelissaApi = stateIsValid;
    } else {
      if (type === 2 || type === 6 || type === 10 || type === 14) {
        apiStatus.ClearinghouseApi_20_24 = stateIsValid;
      }
      if (type === 4 || type === 6 || type === 12 || type === 14) {
        apiStatus.ClearinghouseApi_25_34 = stateIsValid;
      }
      if (type === 8 || type === 10 || type === 12 || type === 14) {
        apiStatus.ClearinghouseApi_35_40 = stateIsValid;
      }
    }
  });

  return apiStatus;
};
export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose?: () => void;
}
const dateErrorMessage =
  "DateTime should be in format 'MM/dd/yyyy HH:mm:ss', A value for the 'DocumentDate' parameter or property was not provided.";
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <img src={CloseIcon} alt="close icon" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

type ModeConfig = {
  title: string;
  bodyText: string;
  specialBlock?: React.ReactNode;
};

const textConfig = {
  'aries+': {
    step2: 'Upload AIRES/AIRES+ files',
    loanTitle: 'AIRES/AIRES+ Loan File',
    shareTitle: 'AIRES/AIRES+ Share File',
  },
};

const Modal: FC<ModalProps> = ({
  buttonText = 'Submit Member data',
  withIcon = true,
  isLid = false,
  color = 'secondary',
  colorOverride,
  img,
  width,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enrichedStatus } = useEnrichment();
  const [isEnrichmentOpen, setIsEnrichmentOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loanErrorMessage, setLoanErrorMessage] = useState(
    'This field is required'
  );
  const [shareErrorMessage, setShareErrorMessage] = useState(
    'This field is required'
  );
  const [open, setOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [loanFile, setLoanFile] = useState<File | undefined>();
  const [shareFile, setShareFile] = useState<File | undefined>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [alertErrorMessage, setAlertErrorMessage] = useState('');
  const [loanName, setLoanName] = useState('');
  const [shareName, setShareName] = useState('');
  const [loanDrop, setLoanDrop] = useState(false);
  const [shareDrop, setShareDrop] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setErrorMessage('');
    setIsError(false);
  };

  const onLoanDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0 || acceptedFiles[0] == undefined) {
      setLoanErrorMessage(
        'This file format is not supported. Only .acmd is supported'
      );
      setIsValid(true);
      setIsError(true);
    } else if (acceptedFiles.length >= 0) {
      setLoanDrop(!loanDrop);
      setLoanErrorMessage('');
      let fileName = acceptedFiles[0].name;
      if (fileName.length > 24) {
        fileName = fileName.substring(0, 22) + '...';
        setLoanName(fileName);
      } else {
        setLoanName(fileName);
      }
      setLoanFile(acceptedFiles[0]);
      setIsError(false);
    }
  }, []);

  const onShareDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0 || acceptedFiles[0] == undefined) {
      setShareErrorMessage(
        'This file format is not supported. Only .acmd is supported'
      );
      setIsValid(true);
      setIsError(true);
    } else if (acceptedFiles.length >= 0) {
      setShareDrop(!shareDrop);
      setShareErrorMessage('');
      let fileName = acceptedFiles[0].name;
      if (fileName.length > 24) {
        fileName = fileName.substring(0, 22) + '...';
        setShareName(fileName);
      } else {
        setShareName(fileName);
      }
      setShareFile(acceptedFiles[0]);
      setIsError(false);
    }
  }, []);

  const handleClickOpen = () => {
    if (enrichedStatus) {
      setIsEnrichmentOpen(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    setIsError(false);
    setIsEnabled(false);
    setShareFile(undefined);
    setLoanFile(undefined);
    setLoanName('');
    setShareName('');
    setLoanDrop(false);
    setShareDrop(false);
    setAlertErrorMessage('');
    setSelectedDate(null);
    setIsValid(false);
    setOpen(false);
  };

  const removeLoanFile = (
    setFile: (file: File | undefined) => void,
    setDrop: (file: boolean) => void
  ) => {
    setFile(undefined);
    setDrop(false);
  };

  const onSubmit = () => {
    setIsValid(true);
    if (selectedDate == null) {
      setErrorMessage('This field is required.');
      setIsError(true);
    }
    if (!loanFile || loanFile === null) {
      setLoanErrorMessage('This field is required.');
      setIsError(true);
    }
    if (!shareFile || shareFile === null) {
      setShareErrorMessage('This field is required.');
      setIsError(true);
    }
    if (!loanDrop || !shareDrop) {
      return;
    }
    if (isError) {
      return;
    }

    if (loanFile && shareFile && selectedDate) {
      const formData = new FormData();
      const formattedDate = new Date(selectedDate);
      const options = {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      } as Intl.DateTimeFormatOptions;
      formData.append('loanFile', loanFile);
      formData.append('shareFile', shareFile);
      formData.append(
        'documentDate',
        `${formattedDate.toLocaleString('en-US', options) + ' 00:00:00'}`
      );

      setIsEnabled(true);
      setIsLoading(true);
      dataManagmentHttpClient
        .post('/api/datamanagement/memberdata', formData)
        .then((response: any) => {
          setIsEnabled(false);
          setShareFile(undefined);
          setLoanFile(undefined);
          setLoanName('');
          setShareName('');
          setLoanDrop(false);
          setShareDrop(false);
          refetchAiresFilesTableDataForPage();
          setIsLoading(false);
          if (response.messageType === 1) {
            if (
              response.errorMessage ===
              'The file(s) was prepared with an outdated version of the Data Preparer. Please download the new version of the app, prepare the files and upload them.'
            ) {
              setAlertErrorMessage(response.errorMessage);
            } else {
              toast.show({
                type: 'error',
                title: 'Upload Failed',
                content: response.errorMessage,
                duration: 6000,
              });
            }
          } else {
            handleClose();
          }
          if (isLid) {
            navigate('/data-management');
          }
        })
        .catch((error: any) => {
          setIsEnabled(false);
          setIsLoading(false);
          setIsError(true);
          setSelectedDate(null);
          if (error?.data?.errorMessage === dateErrorMessage) {
            setErrorMessage(
              "Document Date should be in the format 'MM/DD/YYYY'"
            );
          } else {
            setErrorMessage(
              error?.data?.errorMessage.replace('Invalid timestamp.', '')
            );
          }
        });
    }
  };

  const refetchAiresFilesTableDataForPage = () => {
    queryClient.invalidateQueries([`Aries-Map-Status`]);
    queryClient.invalidateQueries([`Aires-files-table-data`]);
    localStorage.setItem('isProcessingModalClose', 'false');
  };

  const classes = useStyles();
  return (
    <>
      <EnrichmentModal
        isOpen={isEnrichmentOpen}
        description="File cannot be uploaded because the enrichment process you started earlier is still running. Please wait until it finishes."
        cancelFunc={() => {
          setIsEnrichmentOpen(false);
        }}
        title="File Upload Unavailable"
      />
      <div className="modal-button-el">
        {isLid ? (
          <span onClick={handleClickOpen} className={`${s.ariesButton}`}>
            Add dates of birth
          </span>
        ) : (
          <Button
            startIcon={
              withIcon && <img src={img || documentIcon} alt="document icon" />
            }
            size="large"
            variant="contained"
            color={color}
            style={{
              background: `${colorOverride || '#2B39B9'} `,
              color: '#FFFFFF',
              width: `${width || '100%'} `,
              padding: `${width ? '0' : '8px 22px'} `,
            }}
            onClick={handleClickOpen}
            className={classes.button}
          >
            <span
              style={{
                fontSize: withIcon ? '12px' : '14px',
              }}
            >
              {buttonText}
            </span>
          </Button>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <>
            <DialogTitle
              id="customized-dialog-title"
              onClose={isLoading ? undefined : handleClose}
            >
              <span className={`${s.modal__heading}`}>Upload Data</span>
            </DialogTitle>
            {isLoading ? (
              <DialogContent>
                <div className={s.loading}>
                  <Loader />
                  <h2 className={s.loading__title}> Please, wait a moment </h2>
                  <p className={s.loading__text}>
                    Your files are currently in the process of uploading to our
                    system. Due to the size and number of files, this may take a
                    few minutes.
                  </p>
                </div>
              </DialogContent>
            ) : (
              <>
                {' '}
                <DialogContent>
                  <a
                    className={`${s.aries}`}
                    target="_blank"
                    href="https://info.cucollaborate.com/hubfs/analyze-cu/AIRES%20and%20AIRES%20Plus%20Specification.pdf"
                  >
                    Learn about AIRES/AIRES+ files
                  </a>
                  <div className={`${s.modal__body}`}>
                    <h5 className={`${s.modal__title}`}>
                      <span className={`${s.modal__title__step}`}>1</span>
                      <span className={`${s.modal__body__item}`}>
                        <span className={`${s.modal__body__item__block}`}>
                          Download Data Preparer to prepare the data
                          <span
                            className={`${s.modal__body__item__block__icon}`}
                          >
                            <CircleTooltip
                              fullWidth
                              description={`Data Preparer is a desktop application which will secure your members' data by removing sensitive information such as Social Security number, account number and Names from AIRES files`}
                            />
                          </span>
                        </span>
                        <p className={`${s.modal__title__subText}`}>
                          You can skip this step if you have already installed
                          it
                        </p>
                      </span>
                    </h5>
                  </div>

                  <DownloadWidget />
                  <div className={`${s.line}`} />
                  <div
                    className={`${s.modal__body}`}
                    style={{ marginTop: '24px' }}
                  >
                    <h5 className={`${s.modal__title}`}>
                      <span className={`${s.modal__title__step}`}>2</span>
                      {textConfig['aries+'].step2}
                    </h5>
                  </div>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="center">
                      <KeyboardDatePicker
                        disableToolbar
                        placeholder="Select Date"
                        keyboardIcon={
                          <img src={CalendarIcon} alt="Calendar icon" />
                        }
                        autoOk
                        allowKeyboardControl
                        animateYearScrolling
                        variant="inline"
                        format="MM/dd/yyyy"
                        error={isError && errorMessage.length > 0}
                        helperText={isError && errorMessage}
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        InputLabelProps={{
                          style: { pointerEvents: 'auto', color: '#282828' },
                          shrink: true,
                        }}
                        label={
                          <p style={{ color: '#7A89A7', fontWeight: 200 }}>
                            Document Date{' '}
                            <CircleTooltip
                              fullWidth
                              description={`Document Date is the date that the data within a file refers to, e.g., if an AIRES file lists loan (or share) accounts as of December 31, 2021, its document date would be 12/31/2021.`}
                            />
                          </p>
                        }
                        value={selectedDate}
                        onChange={handleDateChange}
                        PopoverProps={{
                          style: { ...{ left: '30px', top: '8px' } },
                        }}
                        style={{ width: '100%' }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <h5 className={`${s.modal__title}`}>
                    {textConfig['aries+'].loanTitle}
                  </h5>
                  <FileUpload
                    removeFile={() => removeLoanFile(setLoanFile, setLoanDrop)}
                    onDrop={(file: any) => {
                      onLoanDrop(file);
                      setAlertErrorMessage('');
                    }}
                    accept=".acmd"
                    fileName={loanName}
                    isDropAccepted={loanDrop}
                  />
                  {isValid && !loanDrop && (
                    <p className={`${s.modal__validation__message} my-1`}>
                      {loanErrorMessage}
                    </p>
                  )}
                  <div style={{ marginBottom: '20px' }}>
                    <h5 className={`${s.modal__title}`}>
                      {textConfig['aries+'].shareTitle}
                    </h5>
                    <FileUpload
                      removeFile={() =>
                        removeLoanFile(setShareFile, setShareDrop)
                      }
                      onDrop={(file: any) => {
                        onShareDrop(file);
                        setAlertErrorMessage('');
                      }}
                      accept=".acmd"
                      fileName={shareName}
                      isDropAccepted={shareDrop}
                    />
                    {isValid && !shareDrop && (
                      <p className={`${s.modal__validation__message} my-1`}>
                        {shareErrorMessage}
                      </p>
                    )}
                  </div>
                  {alertErrorMessage && (
                    <div style={{ marginBottom: '6px' }}>
                      <Alert text={alertErrorMessage} />
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.cancelButton}
                    style={{ border: '2px solid #EFF2F7', fontSize: '20px' }}
                  >
                    <span style={{ fontSize: '14px', color: '#2B39B9' }}>
                      Cancel
                    </span>
                  </Button>
                  <Button
                    onClick={onSubmit}
                    size="large"
                    disabled={isEnabled}
                    className={classes.submitButton}
                    variant="contained"
                    color={color}
                  >
                    <span style={{ fontSize: '14px' }}>Submit</span>
                  </Button>
                </DialogActions>
              </>
            )}
          </>
        </Dialog>
      </div>
    </>
  );
};

export { Modal };
